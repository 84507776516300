@import "variables.scss";
@import "./red-hat/stylesheet.css";
@import "custom.scss";
@import "antd/dist/antd.css";// @import "./grapejs_v1.scss";

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

* {
  box-sizing: border-box;
  font-family: "Red Hat Display", sans-serif;
  font-weight: normal;
  font-style: normal;
}
.dropdown-item.active,
.dropdown-item:active {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
}
//Component css start
//CheckBox Css
.radio-toolbar {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}
.radio-toolbar input[type="radio"] {
  display: none;
}

.radio-toolbar label {
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  appearance: none;
  border: 1px solid $radioColorButton;
  cursor: pointer;
  color: $radioColorButton !important;
  min-height: 36px;
  display: flex;
  font-size: 12px;
  transform: unset;
  text-align: center;
  transition: filter 0.1s linear 0s, transform 0.1s ease-out 0s;
  -webkit-box-align: center;
  align-items: center;
  font-weight: bold;
  line-height: 30px;
  user-select: none;
  border-radius: 60px;
  letter-spacing: 1px;
  background-color: $whiteColor;
  color: rgba(0, 0, 0, 0.87);
  grid-template-columns: 1fr;
  box-shadow: rgb(0 0 0 / 3%) 0px 5px 15px 0px;
  text-transform: initial;
  padding: 8px 20px;
  margin-right: 8px;
  margin-bottom: 12px;
}
.not-selected label {
  color: #000 !important;
  border: 1px solid #aaaaaa !important;
  opacity: 0.6;
}

.radio-toolbar label:hover {
  background-color: $whiteColor;
  border: 1px solid $radioColorButton;
  color: $radioColorButton !important;
}
.radio-toolbar input[type="radio"]:checked + label {
  background-color: $whiteColor;
  border: 1px solid $radioColorButton;
  color: $radioColorButton !important;
}
.not-selected label:hover {
  color: #000 !important;
  border: 1px solid #aaaaaa !important;
}
.accountContainerSpinner {
  .spinner-border {
    --bs-spinner-width: 1rem;
    --bs-spinner-height: 1rem;
    margin-left: 5px;
  }
}
// page main heading
.internalTitle {
  color: $primaryColor;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
}
.border-l-0,
.border-l-0:focus,
.border-l-0:hover {
  border-left: none;
}
.border-r-0,
.border-r-0:focus,
.border-r-0:hover {
  border-right: none;
}
// Page Actions
.pageActionsRight {
  display: flex;
  justify-content: flex-end;
  .dropdown-menu {
    transform: translate(-30px, 45px) !important;
    border: 1px solid #d2d8de;
    border-radius: 8px;
  }
  .select-filter {
    border: 1px solid #929ba8;
    max-width: 130px;
  }
}
.background-none {
  background: none !important;
}
//Loader
.loaderPage {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .componentLoader {
    max-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
}

.publishDropDown {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  z-index: 9999;
  background: #ffffff54;
  align-items: flex-start;
  justify-content: center;
  .componentLoader {
    max-width: 200px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    img {
      max-width: 100%;
    }
  }
}

.loaderPageEditor {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  z-index: 9999;
  background: #ffffff54;
  align-items: center;
  justify-content: center;
  .componentLoader {
    max-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
}

.loaderPageEditor.domainloader {
  width: calc(100% - 240px);
  height: 100%;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.3294117647);
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 240px;
  right: 0;
  .componentLoader {
    max-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
}

//Page Funnellist Component Css
.listPage {
  .col-20 {
    padding-inline: 14px;
    margin-bottom: 3rem;
    .card {
      border: none;
      .cardImage {
        //min-height:350px;
        //max-height:350px;
        border: 1px solid $border-color;
        border-radius: 8px;
        position: relative;
        height: 240px;
        cursor: pointer;
        span {
          min-height: 224px;
        }
        img {
          border-radius: 8px;
        }
      }
      .card-body {
        p {
          color: $primaryColor;
          font-size: 18px;
          font-weight: 500;
        }
        .btn-secondary {
          background-color: transparent;
          border: none;
          padding: 0px;
          box-shadow: none !important;
          &:focus {
            background-color: transparent;
            border: none;
          }
        }
      }
      .subTitle {
        color: $lightGreyHelpingText;
        font-size: 16px;
        span {
          span {
            font-style: italic;
          }
        }
      }
    }
  }
}
.actionMenu {
  padding: 0 0 15px 0;
  .btn-secondary {
    background-color: transparent;
    border: none;
    padding: 0px;
    box-shadow: none !important;
    &:focus {
      background-color: transparent;
      border: none;
    }
  }
}

.card.card-2 {
  img {
    border-radius: 0 !important;
    position: absolute;
    width: 100%;
  }
  img:nth-child(1) {
    top: 0;
    left: 0;
    right: 0;
    height: 119px !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
  img:nth-child(2) {
    top: 119px;
    height: 119px !important;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
}
.card.card-3 {
  img {
    border-radius: 0 !important;
    position: absolute;
    width: 100%;
  }
  img:nth-child(1) {
    top: 0;
    left: 0;
    height: 119px !important;
    width: 50% !important;
    border-top-left-radius: 8px !important;
  }
  img:nth-child(2) {
    top: 0;
    right: 0;
    width: 50% !important;
    height: 119px !important;
    border-top-right-radius: 8px !important;
  }
  img:nth-child(3) {
    top: 119px;
    height: 119px !important;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
}
.card.card-4 {
  img {
    border-radius: 0 !important;
    position: absolute;
    width: 100%;
  }
  img:nth-child(1) {
    top: 0;
    left: 0;
    height: 119px !important;
    width: 50% !important;
    border-top-left-radius: 8px !important;
  }
  img:nth-child(2) {
    top: 0;
    right: 0;
    width: 50% !important;
    height: 119px !important;
    border-top-right-radius: 8px !important;
  }
  img:nth-child(3) {
    top: 119px;
    left: 0;
    width: 50% !important;
    height: 119px !important;
    border-bottom-left-radius: 8px !important;
  }
  img:nth-child(4) {
    top: 119px;
    right: 0;
    width: 50% !important;
    height: 119px !important;
    border-bottom-right-radius: 8px !important;
  }
}
//Page Funnellist Component Css
.listTemplate {
  .col-3 {
    margin-bottom: 2rem;
    .card {
      border: none;
      cursor: pointer;
      .cardImage {
        border: 1px solid $border-color;
        border-radius: 8px;
        //span{
        //  min-height: 350px;
        //  max-height: 350px;
        //}
        img {
          border-radius: 8px;
          //min-height: 352px;
          //max-height: 352px;
          height: 240px;
          //object-fit: cover;
        }
      }
      .card-body {
        h5,
        .pageTitleName {
          color: $primaryColor;
          font-size: 18px;
          font-weight: 500;
          text-transform: capitalize;
        }
        p {
          color: $lightGreyHelpingText;
          font-size: 16px;
        }
      }
    }
  }
}
.listTemplate .col-3:first-child img {
  height: 240px;
  object-fit: cover;
}
.pageTitleName {
  color: $primaryColor;
  font-size: 18px;
  font-weight: 500;
}
//Create Page Modal
.creatPageModal {
  .modal-header {
    border: none;
    padding: 32px 32px 0px 32px;
  }
  .modal-title {
    font-size: 18px;
    font-weight: bold;
    color: $primaryColor;
  }
  .modal-body {
    padding: 24px 32px;
    label {
      margin-bottom: 12px;
      font-size: 16px;
      color: $primaryColor;
    }
    input {
      border: 1px solid $greyOutine;
      border-radius: 4px;
      opacity: 1;
      height: 40px;
    }
  }
  .modal-footer {
    padding: 0 32px 32px;
    border: none;
    justify-content: flex-start;
    .spinnerContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      .spinner-border {
        margin-left: 10px;
        --bs-spinner-width: 22px;
        --bs-spinner-height: 22px;
        --bs-spinner-border-width: 0.1em;
      }
    }
  }
}
//Component css End

// tabs css Start
.tabsLayout {
  .tabsNav {
    border-bottom: 1px solid $border-color;
    .nav-link {
      color: $primaryColor;
      font-weight: 500;
      padding: 15px 27px;
    }
    .active.nav-link {
      color: $primaryColor;
      border-bottom: 3px solid $primaryColor;
      font-weight: bold;
    }
  }
}
// tabs css End

.mainContainer {
  max-width: 100%;
  width: 100%;
  min-height: 100vh;
  .internalContainer {
    width: calc(100% - 240px);
    margin-left: 240px;
    height: 100vh;
    //background: $primaryColor;
  }
}
/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 1600px) {
  .mainContainer .internalContainer {
    width: calc(100% - 220px);
    margin-left: 220px;
  }
}
@media screen and (max-width: 1150px) {
  .mainContainer .internalContainer {
    width: calc(100% - 200px);
    margin-left: 200px;
  }
  .left-side-wrapper {
    max-width: initial;
    width: 100%;
    padding: 0;
    position: relative;
  }
  .right-side-wrapper {
    display: none;
  }
}
@media screen and (max-width: 900px) {
  .mainContainer .internalContainer {
    width: calc(100% - 180px);
    margin-left: 180px;
  }
}
@media screen and (max-width: 600px) {
  .mainContainer .internalContainer {
    width: calc(100% - 150px);
    margin-left: 150px;
  }
}
// publish dropdown
.publish_dropdown {
  .dropdown-menu {
    min-width: 350px;
    /* margin-top: 10px; */
    right: 0 !important;
    top: 200px !important;
    left: unset !important;
  }
  .dropdown-menu:before {
    content: "";
    border-bottom: 14px solid #343a40;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    position: absolute;
    top: -10px;
    right: 20px;
  }
  .dropdown-header {
    border-bottom: 1px solid $primaryColor;
    color: $lightGreyHelpingText;
  }
  .dropdown-item {
    border-bottom: 1px solid $primaryColor;
    padding: 10px 13px 10px;
  }
  .dropdown-item:last-child {
    border: none;
  }

  label {
    margin-left: 15px;
    color: #b2b9c3;
    margin-bottom: 0;
    font-size: 14px;
    word-break: break-all;
  }
  small {
    display: block;
    margin-left: 15px;
    color: $border-color;
    a {
      text-decoration: underline !important;
      color: $border-color !important;
    }
    a:hover {
      background: none;
    }
  }

  .primary-btn-modal {
    height: 30px;
    padding: 7px 30px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
  }
  .btn-grey-outline {
    border: 1px solid $lightGreyHelpingText;
    border-radius: 4px;
    font-size: 12px;
    padding: 7px 30px;
    height: 30px;
    font-weight: 500;
  }
  .footer-action {
    padding: 10px 10px 5px;
  }
}
.errorMessage {
  background: var(--unnamed-color-f7ebed) 0% 0% no-repeat padding-box;
  border: 1px solid $dangerColor;
  border-radius: 4px;
  opacity: 1;
  background: $dangerLightColor;
  color: $dangerColor;
  margin-bottom: 0;
  margin-top: 20px;
  padding: 8px 12px;
  font-size: 13px;
}
.cursor-pointer {
  cursor: pointer;
}
.emptyState {
  h2 {
    font-size: 32px;
    font-weight: bold;
    margin-top: 70px;
  }

  p {
    font-size: 16px;
    line-height: 27px;
    color: #515e72;
    font-weight: 500;
    width: 379px;
    margin-bottom: 35px;
    margin-top: 20px;
  }
  .primary-btn-modal {
    height: 48px;
    border-radius: 4px;
    width: 194px;
    span {
      font-weight: bold;
      font-size: 14px;
      padding-left: 10px;
    }
  }
}
.modal-header {
  border: none;
  padding: 32px 32px 0px 32px;
}
.modal-title {
  font-size: 18px;
  font-weight: bold;
  color: #11213c;
}
.modal-body {
  padding: 24px 32px;
  p {
    font-size: 16px;
    font-weight: 500;
  }
}
.modal-footer {
  padding: 0 32px 32px;
  border: none;
  justify-content: flex-start;
  margin: 0;
  .secondary-btn-modal {
    box-shadow: none;
    height: 40px;
    padding: 9px 22px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
  }
  .btn-danger {
    box-shadow: none;
    height: 40px;
    padding: 9px 22px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    svg {
      margin-top: -5px;
    }
  }
  .primary-btn-modal {
    box-shadow: none;
    height: 40px;
    padding: 9px 22px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
  }
}
button.btn-close {
  font-size: 12px;
  position: relative;
  top: -15px;
  right: -7px;
  font-weight: bold !important;
}
.Connectionaccount {
  margin: auto;
  height: 100vh;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  .row {
    align-items: center;
  }

  .Imageholder img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h1 {
    margin: 50px 0 25px;
    font-size: 32px;
    font-weight: bold;
    line-height: 40px;
    color: $primaryColor;
  }
  p {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
    line-height: 27px;
    color: $primaryColor;
  }
  label {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 12px;
    line-height: 27px;
    color: $primaryColor;
  }
  input {
    border: 1px solid $greyOutine;
    border-radius: 4px;
    height: 40px;
    margin-bottom: 5px;
    max-width: 500px;
  }
  small {
    color: $lightGreyHelpingText;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 7px;
  }
  .accountNameWidth strong {
    max-width: 100px;
  }
  .primary-btn-modal {
    box-shadow: none;
    height: 40px;
    padding: 9px 22px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    margin: 25px 0;
    svg {
      position: relative;
      top: -3px;
      right: 5px;
    }
  }
}
.account-wrapper {
  position: relative;
  .secondary-btn-modal {
    position: absolute;
    top: 40px;
    right: 40px;
  }
}
// analytics start here
.widget-analytics {
  background: #ffffff;
  border: 1px solid $border-color;
  border-radius: 8px;
  min-height: 380px;
  padding: 24px;
  margin-top: 15px;
  margin-bottom: 5px;
  .widget-header {
    h5 {
      color: $lightGreyHelpingText;
      margin-bottom: 4px;
      font-size: 14px;
      font-weight: 600;
      line-height: 32px;
    }
    h2 {
      color: $primaryColor;
      font-size: 28px;
      line-height: 32px;
      font-weight: 500;
      margin-bottom: 0;
    }
    h4 {
      font-size: 16px;
      line-height: 32px;
      font-weight: 600;
      margin-bottom: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      svg {
        position: relative;
        top: -4px;
      }
    }
  }
}
.widget-header {
  min-height: 110px;
  button {
    background-color: $whiteColor;
    color: $lightGreyHelpingText;
    border-color: transparent;
    font-weight: 700;
    font-size: 14px;
    margin-right: 16px;
    border-radius: 24px;
    height: 48px;
    padding: 10px 25px;
    text-transform: capitalize;
  }
  button:hover,
  button:focus {
    background-color: $whiteColor;
    color: $tabColorActive;
    outline: none;
    box-shadow: none !important;
  }
  button.active {
    border-color: $tabColorActive;
    color: $tabColorActive;
    background-color: $whiteColor;
  }
}
.widget-graph {
  margin-top: 5px;
}
.headingTitle {
  h6 {
    font-size: 16px;
  }
  h1 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 0px;

    span {
      font-weight: bold;
      text-transform: capitalize;
    }
  }
}
.widget-analytics .listPage .col-20 {
  margin-bottom: 15px;
}
.link-primary {
  color: #008ce3 !important;
  font-size: 16px;
  font-weight: bold;
}
.tableSet {
  border: 1px solid $border-color;
  border-radius: 8px;

  table {
    margin-bottom: 0;
    tr:last-child td {
      border-bottom: none;
    }
    thead th {
      font-size: 14px;
      font-weight: bold;
      padding: 18px 25px !important;
    }

    td {
      padding: 14px 25px !important;
      font-size: 14px;
      vertical-align: bottom;
      svg {
        color: #00bf9c;
        margin-right: 5px;
      }
      button {
        margin-left: 10px;
        svg {
          color: #515f71;
        }
      }
    }
  }
}
.domainList {
  td:last-child {
    text-align: right;
  }
  td:first-child {
    font-weight: bold;
  }
}
.domainInfo {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;

  td + td,
  th + th {
    border-left: 1px solid $border-color;
  }
  tr + tr {
    border-top: 1px solid $border-color;
  }
}

//ant design wizard changes
.ant-steps.ant-steps-horizontal.ant-steps-label-vertical {
  width: 500px;
  margin: auto;
  padding: 28px 50px;
}
.ant-steps-item.ant-steps-item-process.ant-steps-item-active .ant-steps-item-title {
  color: #008ce3;
}
.steps-content {
  border-top: 1px solid $border-color;
  padding: 28px 50px 0;
}
.steps-action {
  padding: 0 50px 28px;
}
.wizardDomain {
  border: 1px solid $border-color;
  border-radius: 8px;
}
.ant-steps-item.ant-steps-item-wait .ant-steps-item-icon {
  background: #e8ebee;
  color: #717c8d;
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #717c8d;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: none;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
  color: #008ce3;
}
.ant-steps-item.ant-steps-item-finish .ant-steps-item-icon {
  background: #008ce3;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #fff;
  top: -4px;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background: #e8ebee;
}
.ant-steps-item-tail::after {
  height: 3px;
  background: #e8ebee;
}
.ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 58px;
  padding: 3.5px 16px;
}
.ant-steps-label-vertical .ant-steps-item-icon {
  display: inline-block;
  margin-left: 42px;
  z-index: 9999;
}
.steps-content {
  h4 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  p {
    font-size: 16px;
    color: #515e72;
  }
  .checkboxClass {
    label.form-label {
      font-size: 16px;
      font-weight: 700;
      margin: 3px 10px 0;
    }
    input {
      height: 20px;
      width: 20px;
    }
  }
  td svg {
    color: #11213c !important;
    margin: 0 5px;
  }
}
.manualAddons {
  h4 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  p {
    font-size: 16px;
    color: #515e72;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: 14px;
    line-height: 28px;
    color: #008ce3;
  }
}
.step-2 {
  label {
    font-size: 16px;
    color: #11213c;
    font-weight: 500;
  }
  input {
    height: 40px;
  }
  input:hover,
  input:focus,
  input:active {
    box-shadow: none !important;
    border-color: #ced4da;
  }
  select {
    height: 40px;
  }
  small {
    font-size: 16px;
    padding: 8px 0;
    display: block;
    span {
      font-weight: 600;
    }
  }
  .checkboxClass {
    label.form-label {
      font-weight: 500;
    }
  }
}
.input-group input {
  border-left: none;
}
span.input-group-text {
  background: none;
  color: #b2b9c3;
  text-align: center;
  padding: 0 0px 0 17px;
}
.step-3 {
  input {
    height: 40px;
  }
}
div#check-list ul {
  list-style-type: none;
  margin: 0 0 20px;
  padding: 0;
  li {
    display: flex;
    font-size: 18px;
    line-height: 44px;
    font-weight: bold;
    div {
      margin-right: 15px;
      img {
        width: 20px;
      }
    }
  }
}

table.domainList.table td img {
  margin-right: 8px;
  height: 14px;
  position: relative;
  top: -2px;
}
.domain-info ul li {
  color: #000000;
  font-size: 14px;
  margin: 0 16px;
  img {
    margin-right: 6px;
  }
}
table.domainList.table {
  .badge.bg-success {
    background-color: #fff !important;
    color: #00bf9c;
    border: 1px solid #00bf9c !important;
    font-size: 12px;
    padding: 6px 14px;
    border-radius: 17px;
  }
  .badge.text-dark.badge.bg-light {
    background-color: #fff !important;
    color: #11213c;
    border: 1px solid #11213c !important;
    font-size: 12px;
    padding: 6px 14px;
    border-radius: 17px;
  }
}
.checkboxClass.input-check input {
  height: 17px;
  width: 17px;
  margin-right: 8px;
}
.radio-check-custom.form-check {
  border-bottom: 1px solid #11213c;
  padding: 10px 0px 10px;
  input {
    margin-left: 15px;
    outline: none !important;
    box-shadow: none !important;
  }
}

.domain-list-drop {
  overflow-y: auto;
  max-height: 200px;
}
.radio-check-custom.opacity-dim.form-check {
  opacity: 0.5;
}
.publish_dropdown {
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #98a99d;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #98a99d;
  }
}
// Integration List
.instList {
  h3 {
    text-align: left;
    font-size: 22px;
    letter-spacing: -0.48px;
    color: $primaryColor;
    font-weight: bold;
    margin-bottom: 20px;
  }
  li {
    background: $whiteColor;
    border: 1px solid $border-color;
    border-radius: 8px;
    margin: 16px 16px 0 0 !important;
  }
  .active {
    background: #f2f6f9;
  }
}
h4.main-heading {
  text-align: left;
  font-size: 22px;
  letter-spacing: -0.48px;
  font-weight: bold;
  margin-bottom: 20px;
  color: $primaryColor;
}
.instedList {
  h3 {
    text-align: left;
    font-size: 22px;
    letter-spacing: -0.48px;
    color: $primaryColor;
    font-weight: bold;
    margin-bottom: 20px;
  }
  li {
    background: $whiteColor;
    border: 1px solid $border-color;
    border-radius: 8px;
    margin: 16px 16px 0 0 !important;

    .integratedApp {
      display: flex;
      padding: 20px 30px;
      align-items: center;
      min-width: 295px;
      img {
        max-height: 60px;
      }
      .text-details {
        padding-left: 35px;
      }
    }
    h5 {
      font-size: 16px;
      font-weight: 600;
    }

    h6 {
      font-size: 14px;
      color: #717c8d;
    }
  }
}
.ProfileInfo {
  display: flex;
  align-items: center;
  margin: 26px 0 26px;
  .username {
    height: 90px;
    width: 90px;
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background: $SecondaryColorGreen;
    font-size: 30px;
    font-weight: bold;
    text-transform: capitalize;
    color: #fff;
  }
  .text-details {
    padding-left: 35px;
  }
  h5 {
    font-size: 18px;
    font-weight: 600;
  }

  h6 {
    font-size: 18px;
    color: #717c8d;
  }
}
table.workspacemodal {
  border: 1px solid #d2d8de;
  border-radius: 12px !important;
  border-collapse: inherit;
  margin: 20px 0 35px;
  thead {
    tr {
      th {
        font-size: 16px;
        font-weight: bold;
        padding: 14px 24px;
      }
    }
  }
  tbody {
    tr td {
      font-size: 16px;
      padding: 14px 24px;
      vertical-align: middle;
    }
    tr td:last-child {
      text-align: right;
      button {
        background: none;
        border: none;
        color: $primaryColor;
      }
      button:hover,
      button:focus {
        background: none;
        border: none;
        color: $primaryColor;
      }
    }
    tr:last-child td {
      border: none;
    }
  }
}
small.helpingtext {
  font-size: 13px;
  padding-top: 8px !important;
  display: block;
}
.accounts-wrapper {
  padding: 0;
  display: flex;
  min-height: 100vh;
}
.accounts-wrapper {
  padding: 0;
  display: flex;
  min-height: 100vh;
}
.right-side-wrapper {
  width: 100%;
  position: relative;
  background-color: #f0f9fe;
  background-image: url("/images/lri.png");
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
}
.left-side-wrapper {
  width: 666px;
  min-width: 666px;
  height: 100%;
  //display: -webkit-box;
  //display: -webkit-flex;
  //display: -moz-flex;
  //display: -ms-flexbox;
  //display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  padding: 80px 100px;
  //padding: 0 100px;
  //vertical-align: middle;
  //display: flex;
  //align-items: center;
  //align-self: center;
  h1 {
    font-size: 32px;
    font-weight: bold;
    margin: 60px 0 30px;
  }
  label {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 7px !important;
  }
  input {
    border: 1px solid #929ba8;
    border-radius: 4px;
    opacity: 1;
    height: 48px;
    margin-bottom: 15px;
  }
  .flexContainer {
    display: flex;
    position: relative;
    .inputField {
      flex: 1;
    }
    button {
      background: none;
      border: none;
      position: absolute;
      right: 10px;
      svg {
        height: 19px;
        width: 20px;
        color: #515e72;
      }
    }
  }

  li {
    font-size: 12px;
    margin-bottom: 5px;
    color: #515e72;
    stong {
      font-weight: bold;
      color: #000;
    }
    svg {
      height: 15px;
      width: 15px;
      margin-right: 8px;
      margin-left: 2px;
    }
  }
  button {
    height: 48px;
    font-size: 14px;
    font-weight: 600 !important;
  }
  h5 {
    font-size: 16px;
    text-align: center;
    margin: 36px 0 16px;
  }
  a.actionIn {
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    display: block;
    margin: auto;
    width: 120px;
  }
  //input[type="password"]
  //{
  //  color: #000;
  //}
}

.createdCheckEmail {
  h1 {
    margin: 240px 0 25px;
  }
  .alert {
    border: 1px solid #00bf9c;
    color: #00bf9c;
    background: #eefdfa;
    padding: 20px 17px;
    display: flex;
    align-items: center;
    font-size: 14px !important;
    margin-bottom: 18px;
    span {
      span {
        font-weight: bold;
      }
    }
    svg {
      margin-right: 17px;
      height: 30px;
      width: 30px;
    }
    p {
      margin-bottom: 30px;
    }
    .resendLink {
      font-size: 16px;
      font-weight: bold;
      svg {
        margin-right: 6px;
      }
    }
  }
}
.left-side-wrapper input.form-check-input {
  height: 15px !important;
  width: 15px;
}
.login-flow {
  h1 {
    margin: 150px 0 6px;
  }
  p {
    font-size: 16px;
    margin-bottom: 30px;
  }
}
.billing-wrapper {
  background: #f2f6f9a6;
  height: 100vh;
  img {
    padding: 30px 50px;
    margin-bottom: 20px;
  }
}
.billing-inner-wrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 20px #11213c14;
  border-radius: 16px;
  opacity: 1;
  padding: 60px;
  h1 {
    font-size: 32px;
    font-weight: bold;
    margin: 6px 0;
  }
  p {
    font-size: 16px;
    margin-bottom: 38px;
  }
}
.feature-wrapper {
  display: flex;
  justify-content: center;
}
.head-price {
  min-height: 315px;
}
.pricing-feature {
  border: 1px solid #313f57;
  border-radius: 12px;
  opacity: 1;
  width: 275px;
  box-shadow: 0px 10px 16px #11213c1a;
  padding: 30px 25px;
  margin: 0 10px;
  h3 {
    font-size: 20px;
    margin-bottom: 0;
  }
  ul {
    font-size: 14px;
    margin: 20px 0 60px;
    li {
      margin-bottom: 12px;
      font-weight: 500;
      svg {
        color: #b2b9c3;
        margin-right: 10px;
        position: relative;
        top: -2px;
      }
    }
  }
  button {
    border: 1px solid #11213c;
    color: #11213c;
    height: 48px;
    font-weight: bold;
    margin-top: 25px;
  }
}
span.badge.bg-primary.rounded-pill {
  background-color: #00a7ff !important;
}
.pricing-feature.user-select {
  border-color: #00bf9c;
  button {
    border: 1px solid #00bf9c;
    color: #fff;
    height: 48px;
    font-weight: bold;
    margin-top: 25px;
    background: #00bf9c;
  }
  button:hover,
  button:focus {
    background: #00bf9c;
    border: 1px solid #00bf9c;
    color: #fff;
  }
  .badge {
    background-color: #c7f9ef !important;
    color: #009679 !important;
    font-size: 12px;
    padding: 6px 20px;
    font-weight: 500;
    margin: 10px 0 4px;
  }
}
.payment-Successful {
  display: block;
  text-align: center;
  button {
    height: 48px;
    padding: 15px 26px;
    font-weight: bold;
    line-height: 0;
  }
}
.bold {
  font-weight: 700 !important;
}
.pass-tab {
  .flexContainer {
    display: flex;
    position: relative;
    .inputField {
      flex: 1;
    }

    button {
      background: none;
      border: none;
      position: absolute;
      right: 10px;
      top: 6px;
      svg {
        height: 19px;
        width: 20px;
        color: #515e72;
      }
    }
  }
}
.text-right {
  text-align: right;
}
button.btn-table-connect {
  border-color: #00bf9c;
  outline: none;
  box-shadow: none;
  color: #00bf9c;
}
.btn-table-connect:hover,
.btn-table-connect:focus {
  border: 1px solid #00bf9c !important;
  outline: none;
  box-shadow: none;
  color: #00bf9c;
}
.or {
  display: flex;
  justify-content: center;
  align-items: center;
  color: grey;
}

.or:after,
.or:before {
  content: "";
  display: block;
  background: #aaaaaa;
  width: 30%;
  height: 1px;
  margin: 0 10px;
}
ul.bottom-nav.nav.flex-column {
  opacity: 0.4;
  pointer-events: none;
}
button.dropdown-item a {
  width: 100% !important;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
  margin-left: 15px;
}
button.dropdown-item a:hover {
  width: 100% !important;
}
.steps-action button {
  height: 40px;
  font-size: 17px;
  padding: 5px 40px;
  border-radius: 7px;
  font-weight: 700 !important;
  margin-right: 10px;
}
.fh-wrapper.text-center {
  a {
    padding: 8px 26px;
  }
  p {
    font-size: 20px;
  }
}

h1.old-price {
  color: #f00;
  text-decoration: line-through;
  font-size: 26px;
  margin-top: 15px;
}

table.workspacemodal.table select {
  width: 55%;
  margin: auto;
}
button.primary-btn-modal.shadow-none.save-button.btn.btn-secondary {
  background-color: #00bf9c;
  color: #fff;
  border-color: transparent;
}
.domainsinfo .emptyState {
  min-height: 380px;
  display: flex !important;
  justify-content: center;
  align-self: center;
  img {
    display: block;
    margin: 10px auto;
  }
  button {
    display: block;
    margin: auto;
  }
}

button.dropdown-item:hover {
  color: #40a9ff;
}
.embed-button.right-embed-button {
  position: relative;
}
.embed-button.right-embed-button a {
  position: fixed;
  right: 0;
  top: 48%;
  border-radius: 5px;
  z-index: 9999;
  transform: rotate(90deg);
  transform-origin: 74% 74%;
  font-size: 18px;
  font-weight: bold;
  font-family: Arial, Verdana, Helvetica, sans-serif;
  cursor: pointer;
  padding: 7px 30px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background: rgb(54, 128, 251);
  box-shadow: rgb(17 17 17) 0px 0px 3px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.embed-button.right-embed-button a:hover {
  color: #fff;
}
.cke {
  position: absolute;
  min-width: 497px !important;
  top: 10%;
}

.cke_editable p {
  display: block;
  margin-block-start: 0em !important;
  margin-block-end: 0em !important;
  margin-inline-start: 0em !important;
  margin-inline-end: 0em !important;
}

.spinner-btn .spinnerContainer .spinner-border {
  height: 18px !important;
  width: 18px !important;
  vertical-align: middle;
}
button#Tooltipdis svg {
  height: 20px;
  width: 23px;
}

.mt-1.mb-2.float-end.btn-group .btn-outline-secondary {
  border-color: #d2d8de;
  padding: 12px 18px;
  font-size: 13px;
  font-weight: bold;
  color: #11213c;
}
.mt-1.mb-2.float-end.btn-group .btn-outline-secondary:hover {
  border-color: #d2d8de;
  color: #11213c;
  background: #f2f6f9;
}
.mt-1.mb-2.float-end.btn-group button.btn.btn-outline-secondary.active,
.mt-1.mb-2.float-end.btn-group .btn-outline-secondary:focus {
  background: #f2f6f9;
}
.react-datepicker__input-container input {
  height: 45px;
  margin-left: 10px;
  border: 1px solid #d2d8de;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  color: #11213c;
  outline: none;
  box-shadow: none;
  min-width: 192px;
  border-radius: 4px;
}
.react-datepicker__input-container input.selectedrange {
  background: #f2f6f9;
}
.modal-footer .btn-danger svg {
  margin-top: -2px;
  margin-right: 5px;
}
.plan_subscription {
  border: 1px solid $border-color;
  border-radius: 8px;
  padding: 24px 20px;
  .heading_name {
    h5 {
      font-size: 16px;
      font-weight: bold;
      margin-right: 20px;
      margin-top: 10px;
    }
    .badge.bg-secondary {
      background-color: $whiteColor !important;
      color: $text-success;
      border: 1px solid $text-success;
      display: flex;
      align-self: auto;
      padding: 5px 20px;
      align-items: center;
      text-transform: capitalize;
    }
    span.dangerColor {
      border-color: #c41e1e !important;
      color: #c41e1e !important;
    }
    span.warningColor {
      border-color: #e9ac12 !important;
      color: #e9ac12 !important;
    }
  }
  h6 {
    color: $lightGreyHelpingText;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 7px;
  }
  p {
    font-size: 18px;
    color: $primaryColor;
    font-weight: 500;
    margin: 0;
  }
}
.billing_table {
  td {
    font-weight: 500;
  }
}
.alc {
  align-items: center;
}
.choose-plan.choose-danger {
  background: #c41e1e;
}
.choose-plan.choose-warning {
  background: #e9ac12;
}
.payment_method {
  border: 1px dashed #3680fb;
  border-radius: 17px;
  padding: 15px 0;
  text-align: center;
  .head {
    border-bottom: 1px dashed #d2d8de;
    padding: 5px 0 20px;
  }
  .body {
    border: 1px dashed #3680fb;
    padding: 20px 30px;
    margin: 20px 35px;
    text-align: left;
    p {
      margin: 0;
      font-size: 16px;
    }
  }
  .footer {
    padding: 0 35px 10px;
    button {
      display: flex;
      width: 100%;
      justify-content: center;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}

.card-no-space {
  letter-spacing: 7.5px;
}
.choose-plan {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #00af8d;
  color: #fff;
  padding: 10px 25px;
  font-size: 16px;
}
.center-content-closed-store {
  text-align: center;
  position: relative;
  background-color: #ffff;
  box-shadow: 0px 0px 12px #3680fb29;
  border-radius: 6px;
  opacity: 1;
  min-height: 450px;
  background-repeat: no-repeat;
  background-position: right bottom;
  h4 {
    font-size: 18px;
    font-weight: 700;
    margin: 20px 0 10px;
  }
  p {
    margin-bottom: 0 !important;
  }
}
.reopen-store {
  background: #f6f6f6;
  border: 2px dashed #9c9fa4;
  padding: 20px;
  border-radius: 7px;
  min-height: 150px;
  text-align: left;
  position: relative;
  h3 {
    font-size: 18px;
    font-weight: 700;
  }

  h5 {
    font-size: 16px;
    margin-top: 15px;
  }
  a.move-action-link svg {
    color: #00af8d;
    float: right;
    position: relative;
    bottom: -25px;
    right: 0px;
  }
}
.trialEnd {
  opacity: 0.8;
  pointer-events: none;
}
.pagination {
  a.page-num.active {
    background: #00a7ff;
    color: #fff !important;
  }
  a.page-num {
    background: #fff;
    border: 1px solid #00a7ff;
    color: #00a7ff !important;
    padding: 7px 12px;
    border-radius: 4px;
    margin-right: 10px;
  }
  .disabled a {
    opacity: 0.3;
    cursor: not-allowed !important;
  }
}

.gjs-sm-property.gjs-sm-radio.gjs-sm-property__float.gjs-sm-property--full {
  display: none;
}
.gjs-sm-property.gjs-sm-my-custom-prop.gjs-sm-property__text-align {
  width: 100%;
  margin-bottom: 5px;
  .gjs-fields div {
    border: none;
    box-shadow: none;
    border-radius: 2px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    font-size: 0.75rem;
    padding: 10px 5px;
    flex-wrap: wrap;
    align-items: flex-end;
    width: 100%;
    button {
      flex: 1 1 auto;
      text-align: center;
      cursor: pointer;
      display: block;
      padding: 5px;
      border: none;
      background: rgba(0, 0, 0, 0.2);
    }
  }
  .gjs-sm-clear {
    display: none;
  }
}
.gjs-sm-property.gjs-sm-my-custom.gjs-sm-property__text-align {
  width: 100%;
  margin-bottom: 5px;
  .gjs-fields div {
    border: none;
    box-shadow: none;
    border-radius: 2px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    font-size: 0.75rem;
    padding: 10px 5px;
    flex-wrap: wrap;
    align-items: flex-end;
    width: 100%;
    button {
      flex: 1 1 auto;
      text-align: center;
      cursor: pointer;
      display: block;
      padding: 5px;
      border: none;
      background: rgba(0, 0, 0, 0.2);
    }
  }
  .gjs-sm-clear {
    display: none;
  }
}
button.align-button.active {
  background-color: rgba(255, 255, 255, 0.2) !important;
}
button.height-button.active {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

button.height-button[data-height="small"].active {
  background-color: rgba(255, 255, 255, 0.2) !important;
}
.gjs-sm-sector__resizing .gjs-sm-property__text-align .gjs-sm-label .gjs-sm-icon[title=""] {
  display: none;
}

.custom-button {
  box-sizing: border-box;
  background-color: #00a7ff;
  width: 100%;
  padding: 2px;
  font-size: 28px;
  color: #fff;
  display: block;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  font-weight: 700;
  margin: 12px auto;
  max-width: 1140px;
}
