$primaryColor:#11213C;
$SecondaryColorBlue:#00A7FF;
$SecondaryColorGreen:#00BF9C;
$whiteColor:#fff;
$border-color:#D2D8DE;
$lightGreyHelpingText: #717c8d;
$greyOutine: #929BA8;
$tabColorActive: #635AC7;
$radioColorButton: #008CE3;
$dangerColor: #EF6876;
$dangerLightColor: #F7EBED;
$text-success: #00AF8D;
$text-dangerous: #EA2D4A;




//Hover colors
$SecondaryColorGreenHover:#50D6BD;
